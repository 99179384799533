import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import "./View.css";
import ReactToPrint from "react-to-print";


const arr = (x) => Array.from(x);
const num = (x) => Number(x) || 0;
const str = (x) => String(x);
const isEmpty = (xs) => xs.length === 0;
const take = (n) => (xs) => xs.slice(0, n);
const drop = (n) => (xs) => xs.slice(n);
const reverse = (xs) => xs.slice(0).reverse();
const comp = (f) => (g) => (x) => f(g(x));
const not = (x) => !x;
const chunk = (n) => (xs) =>
  isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

export function commaFormate(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
// Make sure you include the html2pdf library in your HTML file.
// You can get it from https://html2pdf.app/

class SalesOrderInternalModal extends React.Component {
  constructor(props) {
    super(props);
    this.pdfRef = React.createRef();
  }

  dateConverter = (date) => {
    var d = new Date(date);
    d = d.toString();
    console.log("d");
    console.log(d);
    d = d.split(" ");
    //   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //   day = ("0" + date.getDate()).slice(-2);
    d = [d[2], d[1], d[3]].join("-");
    return d;
  };

  getOldPrice = (price, vat, ait, discount) => {
    var lineTotalVat = price * (vat / 100);
    var lineTotalAit = price * (ait / 100);
    console.log("price");
    console.log(price);
    console.log("lineTotal");
    console.log(lineTotalVat + lineTotalAit + discount);
    var final = price - (lineTotalVat + lineTotalAit + discount);
    return final;
  };

  getVatAmount = (price, vat) => {
    var lineTotalVat = price * (vat / 100);

    return lineTotalVat.toFixed(2);
  };

  getAitAmount = (price, ait) => {
    var lineTotalAit = price * (ait / 100);
    return lineTotalAit.toFixed(2);
  };

  getTotalPrice = (arr, vat, ait, discount, adjustment) => {
    var sum = 0;
    arr.map((single) => {
      sum = sum + single.sodFinalCalculatedUnitPrice;
    });
    sum =
      parseFloat(sum) +
      parseFloat(vat) +
      parseFloat(ait) -
      (parseFloat(discount) + parseFloat(adjustment));
    console.log("sum");
    console.log(sum);
    return sum.toFixed(2);
  };

  getTotalQuantity = (arr) => {
    var sum = 0;
    arr.map((single) => {
      sum = sum + single.sodQty;
    });

    return parseFloat(sum).toFixed(2);
  };

  numberToEnglish = (n) => {
    var string = n.toString(),
      units,
      tens,
      scales,
      start,
      end,
      chunks,
      chunksLen,
      chunk,
      ints,
      i,
      word,
      words,
      and = "and";

    /* Remove spaces and commas */
    string = string.replace(/[, ]/g, "");

    /* Is number zero? */
    if (parseInt(string) === 0) {
      return "zero";
    }

    /* Array of units as words */
    units = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];

    /* Array of tens as words */
    tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    /* Array of scales as words */
    scales = [
      "",
      "thousand",
      "million",
      "billion",
      "trillion",
      "quadrillion",
      "quintillion",
      "sextillion",
      "septillion",
      "octillion",
      "nonillion",
      "decillion",
      "undecillion",
      "duodecillion",
      "tredecillion",
      "quatttuor-decillion",
      "quindecillion",
      "sexdecillion",
      "septen-decillion",
      "octodecillion",
      "novemdecillion",
      "vigintillion",
      "centillion",
    ];

    /* Split user argument into 3 digit chunks from right to left */
    start = string.length;
    chunks = [];
    while (start > 0) {
      end = start;
      chunks.push(string.slice((start = Math.max(0, start - 3)), end));
    }

    /* Check if function has enough scale words to be able to stringify the user argument */
    chunksLen = chunks.length;
    if (chunksLen > scales.length) {
      return "";
    }

    /* Stringify each integer in each chunk */
    words = [];
    for (i = 0; i < chunksLen; i++) {
      chunk = parseInt(chunks[i]);

      if (chunk) {
        /* Split chunk into array of individual integers */
        ints = chunks[i].split("").reverse().map(parseFloat);

        /* If tens integer is 1, i.e. 10, then add 10 to units integer */
        if (ints[1] === 1) {
          ints[0] += 10;
        }

        /* Add scale word if chunk is not zero and array item exists */
        if ((word = scales[i])) {
          words.push(word);
        }

        /* Add unit word if array item exists */
        if ((word = units[ints[0]])) {
          words.push(word);
        }

        /* Add tens word if array item exists */
        if ((word = tens[ints[1]])) {
          words.push(word);
        }

        /* Add 'and' string after units or tens integer if: */
        if (ints[0] || ints[1]) {
          /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
          if (ints[2] || i + 1 > chunksLen) {
            words.push(and);
          }
        }

        /* Add hundreds word if array item exists */
        if ((word = units[ints[2]])) {
          words.push(word + " hundred");
        }
      }
    }

    return words.reverse().join(" ");
  };

  NumInWords = (number) => {
    const first = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const mad = ["", "thousand", "million", "billion", "trillion"];
    let word = "";

    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number % (100 * Math.pow(1000, i));
      if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
        if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
          word =
            first[Math.floor(tempNumber / Math.pow(1000, i))] +
            mad[i] +
            " " +
            word;
        } else {
          word =
            tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
            "-" +
            first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
            mad[i] +
            " " +
            word;
        }
      }

      tempNumber = number % Math.pow(1000, i + 1);
      if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
        word =
          first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] +
          "hunderd " +
          word;
    }
    return word;
  };

  // ==========================================================================
  // Function Declaration :: Convert Numbers to Words :: Indian Format
  // ==========================================================================

  convertNumberToWords = (num) => {
    console.log("num");
    console.log(num);

    var ones = [
      "",
      "One ",
      "Two ",
      "Three ",
      "Four ",
      "Five ",
      "Six ",
      "Seven ",
      "Eight ",
      "Nine ",
      "Ten ",
      "Eleven ",
      "Twelve ",
      "Thirteen ",
      "Fourteen ",
      "Fifteen ",
      "Sixteen ",
      "Seventeen ",
      "Eighteen ",
      "Nineteen ",
    ];
    var tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    if ((num = num.toString()).length > 9)
      return "Overflow: Maximum 9 digits supported";
    const n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    console.log("n");
    console.log(n);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (ones[Number(n[1])] || tens[n[1][0]] + " " + ones[n[1][1]]) + "Crore "
        : "";
    str +=
      n[2] != 0
        ? (ones[Number(n[2])] || tens[n[2][0]] + " " + ones[n[2][1]]) + "Lakh "
        : "";
    str +=
      n[3] != 0
        ? (ones[Number(n[3])] || tens[n[3][0]] + " " + ones[n[3][1]]) +
          "Thousand "
        : "";
    str +=
      n[4] != 0
        ? (ones[Number(n[4])] || tens[n[4][0]] + " " + ones[n[4][1]]) +
          "Hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (ones[Number(n[5])] || tens[n[5][0]] + " " + ones[n[5][1]])
        : "";
    return str;
  };

  getNumbers = (inputNumber, currency) => {
   
    var num = inputNumber;
    var str = num.toString();
    var numarray = str.split(".");
    var a = new Array();
    a = numarray;
   
    inputNumber = a[0];
   
    var s = this.NumToWord(inputNumber);

    if (a[1] === undefined) {
      var c = currency === "BDT" ? " Taka only." : "Dollar only";
      s = s + c;
    } else if (a[1] === "00") {
      var c = currency === "BDT" ? " Taka only." : "Dollar only";

      s = s + c;
    } else if (a[1][0] == 0) {
      var c =
        currency === "BDT"
          ? " Taka and Zero"
          : "Dollar and Zero" + this.NumToWord(a[1][1]) + currency === "BDT"
          ? " poisha only."
          : " penny only.";
      s = s + c;
    } else {
      var c =
        currency === "BDT"
          ? " Taka and "
          : " Dollar and " + this.NumToWord(a[1]) + currency === "BDT"
          ? " poisha only."
          : " penny only.";
      s = s + c;
    }
  
    return s;
  };

  NumToWord = (inputNumber) => {
    var str = new String(inputNumber);
    var splt = str.split("");
    var rev = splt.reverse();
    var once = [
      "Zero",
      " One",
      " Two",
      " Three",
      " Four",
      " Five",
      " Six",
      " Seven",
      " Eight",
      " Nine",
    ];
    var twos = [
      "Ten",
      " Eleven",
      " Twelve",
      " Thirteen",
      " Fourteen",
      " Fifteen",
      " Sixteen",
      " Seventeen",
      " Eighteen",
      " Nineteen",
    ];
    var tens = [
      "",
      "Ten",
      " Twenty",
      " Thirty",
      " Forty",
      " Fifty",
      " Sixty",
      " Seventy",
      " Eighty",
      " Ninety",
    ];

    var numLength = rev.length;
    var word = new Array();
    var j = 0;

    for (var i = 0; i < numLength; i++) {
      switch (i) {
        case 0:
          if (rev[i] == 0 || rev[i + 1] == 1) {
            word[j] = "";
          } else {
            word[j] = "" + once[rev[i]];
          }
          word[j] = word[j];
          break;

        case 1:
          aboveTens();
          break;

        case 2:
          if (rev[i] == 0) {
            word[j] = "";
          } else if (rev[i - 1] == 0 || rev[i - 2] == 0) {
            word[j] = once[rev[i]] + " Hundred ";
          } else {
            word[j] = once[rev[i]] + " Hundred and";
          }
          break;

        case 3:
          if (rev[i] == 0 || rev[i + 1] == 1) {
            word[j] = "";
          } else {
            word[j] = once[rev[i]];
          }
          if (rev[i + 1] != 0 || rev[i] > 0) {
            word[j] = word[j] + " Thousand";
          }
          break;

        case 4:
          aboveTens();
          break;

        case 5:
          if (rev[i] == 0 || rev[i + 1] == 1) {
            word[j] = "";
          } else {
            word[j] = once[rev[i]];
          }
          if (rev[i + 1] !== "0" || rev[i] > "0") {
            word[j] = word[j] + " Lakh";
          }

          break;

        case 6:
          aboveTens();
          break;

        case 7:
          if (rev[i] == 0 || rev[i + 1] == 1) {
            word[j] = "";
          } else {
            word[j] = once[rev[i]];
          }
          if (rev[i + 1] !== "0" || rev[i] > "0") {
            word[j] = word[j] + " Crore";
          }
          break;

        case 8:
          aboveTens();
          break;
        default:
          break;
      }
      j++;
    }

    function aboveTens() {
      if (rev[i] == 0) {
        word[j] = "";
      } else if (rev[i] == 1) {
        word[j] = twos[rev[i - 1]];
      } else {
        word[j] = tens[rev[i]];
      }
    }

    word.reverse();
    var finalOutput = "";
    for (i = 0; i < numLength; i++) {
      finalOutput = finalOutput + word[i];
    }
    return finalOutput;
  };

  async exportToPDF2() {
    const input = document.getElementById("invoiceCapture");
   
    let pdf = new jsPDF("p", "mm", "a4");
    let pdfWidth = pdf.internal.pageSize.getWidth();
    let pdfHeight = pdf.internal.pageSize.getHeight();
    let yOffset = 0;

    let contentHeight = input.clientHeight;
    let totalPages = Math.ceil(contentHeight / pdfHeight);

    for (let page = 1; page <= totalPages; page++) {
      const canvas = await html2canvas(input, {
        windowHeight: contentHeight - yOffset,
        scrollY: yOffset,
      });

      let imgData = canvas.toDataURL("image/png");
      if (page > 1) {
        pdf.addPage();
      }

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      yOffset += pdfHeight;
      if (yOffset < contentHeight) {
        pdf.addPage();
      }
    }

    pdf.save("invoice.pdf");
  }

  async exportToPDF(soSystemNo) {
    window.scrollTo(0, 0);
    const divToPrint = document.querySelector("#invoiceCapture");

    
    html2canvas(divToPrint).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 193;
      const pageHeight = 298;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF("pt", "mm");
      let position = 0;
      doc.addImage(imgData, "PNG", 10, 0, imgWidth, imgHeight + 25);
      heightLeft -= pageHeight;
      // Adding margin to the top of the second page
      const topMarginOnSecondPage = 20; // Adjust this value as needed
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();

        doc.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight + 25);
        heightLeft -= pageHeight;
      }

      // PAGE NUMBERING & General Info
      // Add Page number at bottom-right
      // Get the number of pages
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFont("helvetica", "italic");
      doc.setFontSize(8);

      // For each page, print the page number and the total pages
      for (var i = 1; i <= pageCount; i++) {
        // Go to page i
        doc.setPage(i);
        //Print Page 1 of 4 for example

        doc.text(
          "This is a computer-generated document. No signature is required.                " +
            "Page" +
            String(i) +
            " of " +
            String(pageCount),
          210 - 20,
          297 - 30,
          null,
          null,
          "right"
        );
      }
      doc.save(`invoice-${soSystemNo}.pdf`);
    });
  }
  
  render() {
  

    return (
      <div>
        <Modal
          show={this.props.showModal}
          onHide={this.props.closeModal}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
   
          </Modal.Header>
          <div
            id="invoiceCapture"
            className=" pt-1 "
            style={{ position: "relative" }}
            ref={this.pdfRef}
          >
            <div className="d-flex flex-row justify-content-between align-items-center bg-light w-100 py-4 px-2 invoice-header">
              <div className="text-end pr-5 pl-5">
                <img
                  src={process.env.PUBLIC_URL + "/images/trade.png"}
                  alt=""
                  width="130"
                  height="35"
                  style={{ width: "80px", height: "50px" }}
                />
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <h4 className="fw-bold my-2">TRADE ZENTRUM</h4>
                <h6 className="fw-bold text-secondary mb-1">
                <div style={{ textAlignVertical: "center",textAlign: "center",}}>
                  Reg. Office: House #21, Road #01, Block-C, Banasree, Rampura, Dhaka
                  </div>
                </h6>
                <h6 className="fw-bold text-secondary mb-1 d-flex align-items-center justify-content-between">
                  <div className="fw-bold text-secondary mb-1 d-flex align-items-center">
                    ☎️&nbsp;+8801792 527261
                    {/* <div>01670205047</div> */}
                  </div>{" "}
                  &nbsp; &nbsp; &nbsp;
                  <div className="fw-bold text-secondary mb-1 d-flex align-items-center">
                    📩&nbsp;tradezentrumbd@gmail.com
                    {/* <div>tradezentrumbd@gmail.com</div> */}
                  </div>
                </h6>
              </div>
              <div className="text-end pr-5 pl-5">
                <img
                  src={process.env.PUBLIC_URL + "/images/assutext-logo.jpeg"}
                  alt=""
                  width="130"
                  height="35"
                  style={{ width: "120px", height: "50px" }}
                />
              </div>
            </div>

            <div
              className="p-4"
              style={
                {
                  // backgroundImage:
                  //   "url(" + `${process.env.PUBLIC_URL}/images/invoice.png` + ")",
                }
              }
            >
              <div style={{ textAlign: "center" }}>
                <span
                  style={{
                    fontWeight: "bolder",
                    border: "1px solid #e4e4e4",
                    padding: "5px",
                  }}
                >
                  INVOICE
                </span>
                <br />
                <br />
              </div>
              <Row style={{ marginBottom: "2rem", height: "100px" }}>
              

                <Col md={7} lg={8}>
       
                <div>
                    {this.props.info.stockOutGeneralData?.supplierName ? (
                      <>Supplier Name:&nbsp; : {this.props.info.stockOutGeneralData.supplierName}</>
                    ) : (
                      <>Customer Name:&nbsp; : {this.props.info.stockOutGeneralData?.customerName || "Not Available"}</>
                    )}
                </div>
                <div>
                {this.props.info.stockOutGeneralData?.supplierAddress ? (
                      <>Supplier Address:&nbsp; : {this.props.info.stockOutGeneralData.supplierAddress}</>
                    ) : (
                      <>Customer Address:&nbsp; : {this.props.info.stockOutGeneralData?.customerAddress || "Not Available"}</>
                    )}
                </div>
                
                </Col>
                <Col md={5} lg={4}>
                  <div>
                  Invoice&nbsp;Number:&nbsp; :{" "}
                    {this.props.info.stockOutGeneralData?.soSystemNo ||
                      "1222i3y"}
                  </div>
                  <div>
                    Date Of Issue:&nbsp;
                    {this.dateConverter(
                      this.props.info.stockOutGeneralData.invoiceDate
                    ) || ""}
                  </div>
                  <div>
                  Ref:&nbsp;
                    {
                      this.props.info.stockOutGeneralData.marketingPersonnel || ""
                    }
                  </div>

                  {this.props.info.stockOutGeneralData?.jobTypeName !==
                    "Cash_Sales" &&
                  this.props.info.stockOutGeneralData?.jobTypeName !==
                    "Credit_Sales" ? (
                    <div>
                      Order Type:&nbsp;
                      {this.props.info.stockOutGeneralData?.jobTypeName || ""}
                    
                    </div>
                  ) : (
                    ""
                  )}
                   
                    {this.props.info.stockOutGeneralData?.soCustomerReferance && (
                    
                      <div>
                        Customer Reference:&nbsp;{this.props.info.stockOutGeneralData.soCustomerReferance}
                      </div>
                    
                    )}
                  
             
                </Col>
              </Row>

              <div
                style={{
                  width: "auto",
                  overflowX: "scroll",
                }}
                className="row-scrollers"
              >
                <Table
                  className="mb-0"
                  style={{
                    border: "1px solid #e3e3e3",
                  }}
                >
                  <thead>
                    <tr>
                      <th>PRODUCT NAME</th>
                      <th>QTY(KG)</th>
                      <th className="text-end">PRICE/KG</th>
                      <th className="text-end">AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.info.stockOutItemsData.map((item, i) => {
                      return (
                        <tr id={i} key={i} style={{ height: "20px" }}>

                    <td>
                       
                          {item.productName}
                         
                        
                      </td>

                          <td style={{ width: "70px" }}>{item.sodQty}</td>
                          <td className="text-end" style={{ width: "100px" }}>
                            {this.props.info.stockOutBillData.sobCurrencyName}{" "}
                            {item.sodUnitPrice}
                          </td>
                          <td className="text-end" style={{ width: "100px" }}>
                            {this.props.info.stockOutBillData.sobCurrencyName}{" "}
                            {item.sodUnitPrice * item.sodQty}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <Table
                style={{ marginTop: "0.2rem", border: "1px solid #e3e3e3" }}
              >
                <tbody>
                  <tr className="">
                    <td>
                      <span className="fw-bold text-start">Item(s) :</span>{" "}
                      {this.props.info.stockOutItemsData?.length}
                    </td>

                    <td className="text-start">
                      <span className="fw-bold text-start">Total Quantity :</span>{" "}
                      {this.getTotalQuantity(
                        this.props.info?.stockOutItemsData
                      )}{" "}
                      KG
                    </td>
                    <td className="text-end">
                      <span className="fw-bold text-end"> Subtotal :</span>{" "}
                      {this.props.info.stockOutBillData.sobCurrencyName}{" "}
                      {commaFormate(
                        this.props.info.stockOutBillData.calculatedBill
                      )}
                    </td>
                  </tr>
                  
                  {this.props.info.stockOutBillData.billVatPercantage != 0 && (
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: "100px" }}>
                        VAT :{" "}
                      </td>
                      <td className="text-end" style={{ width: "150px" }}>
                        {this.props.info.stockOutBillData.billVatPercantage}
                        {"%"} {" ~ "}{" "}
                        {this.props.info.stockOutBillData.sobCurrencyName}{" "}
                        {this.getVatAmount(
                          this.props.info.stockOutBillData.calculatedBill,
                          this.props.info.stockOutBillData.billVatPercantage
                        )}
                      </td>
                    </tr>
                  )}
                  {this.props.info.stockOutBillData.billAitPercantage != 0 && (
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: "100px" }}>
                        AIT :{" "}
                      </td>
                      <td className="text-end" style={{ width: "100px" }}>
                        {this.props.info.stockOutBillData.billAitPercantage}
                        {"%"} {" ~ "}{" "}
                        {this.props.info.stockOutBillData.sobCurrencyName}{" "}
                        {this.getAitAmount(
                          this.props.info.stockOutBillData.calculatedBill,
                          this.props.info.stockOutBillData.billAitPercantage
                        )}
                      </td>
                    </tr>
                  )}
                  {this.props.info.stockOutBillData.billDiscountAmount != 0 && (
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: "100px" }}>
                        Discount :{" "}
                      </td>
                      <td className="text-end" style={{ width: "100px" }}>
                        {this.props.info.stockOutBillData.sobCurrencyName}{" "}
                        {parseFloat(
                          this.props.info.stockOutBillData.billDiscountAmount
                        ).toFixed(2)}
                      </td>
                    </tr>
                  )}
                  {this.props.info.stockOutBillData.billAdjustmentAmount !=
                    0 && (
                    <tr className="text-end">
                      <td
                        colspan={3}
                        className="text-end"
                        style={{ width: "100px" }}
                      >
                        <span className="fw-bold">Adjustment : </span>
                        {this.props.info.stockOutBillData.sobCurrencyName}{" "}
                        {parseFloat(
                          this.props.info.stockOutBillData.billAdjustmentAmount
                        ).toFixed(2)}
                      </td>
                    </tr>
                  )}
                  <tr className="text-end">
                    <td className="text-end" colspan={3}>
                      <span className="fw-bold">Total Payable Amount : </span>{" "}
                      {commaFormate(
                        parseFloat(
                          this.props.info.stockOutBillData.finalBill
                        ).toFixed(2)
                      )}{" "}
                      {this.props.info.stockOutBillData.sobCurrencyName}{" "}
                     
                    </td>
                  </tr>
                </tbody>
              </Table>

              <div className="bg-light py-3 px-4 rounded">
                {/* {this.getNumbers(143395.05)} */}
                <div>
                  In Word:
                  {this.getNumbers(
                    parseFloat(
                      this.props.info.stockOutBillData.finalBill
                    ).toFixed(2),
                    this.props.info.stockOutBillData.sobCurrencyName
                  )}
                </div>
              </div>
            </div>

            <div className="p-4 ">
              <Row className="mb-4 ">
                <Col md={3} lg={3}>
                  <div
                    className="fw-bold text-left w-55 h-55 p-1"
                    style={{ height: "140px" ,
        
                  }}
                  >
                    Received By:
                    <div  style={{ textAlignVertical: "left",textAlign: "left",fontSize:"14px"}}>
                    <br/>
                      <div style={{padding:"4px"}}>Signature:</div>
                      <div style={{padding:"4px"}}>Name: </div>
                      <div style={{padding:"4px"}}> Mobile Number :</div>
                                
                      </div>    
                  </div>
             
                </Col>
              </Row>
            </div>
            <footer>
              <span>
              <div style={{ textAlignVertical: "center",textAlign: "center",}}>
                This is a computer-generated document. No signature is required.
                </div>
              </span>
            </footer>
          </div>
          <div className="pb-4 px-4">
            <Row>
              <Col md={6} className="mb-1">
                <Button
                  variant="primary"
                  className="d-flex align-items-center justify-content-center w-100 "
                  onClick={() => {
                    this.exportToPDF(
                      this.props.info.stockOutGeneralData.soSystemNo
                    );
                  }}
                >
                  Export Image as PDF
                </Button>
              </Col>
              <Col md={6} className="mb-1">
               
                <ReactToPrint
                  trigger={() => (
                    <Button
                      variant="primary"
                      className="d-flex align-items-center justify-content-center w-100"
                    >
                      Export as PDF
                    </Button>
                  )}
                  content={() => this.pdfRef.current}
                />
              </Col>
              <Col md={6}>
             
              </Col>
            </Row>
          </div>
        </Modal>
        <hr className="mt-4 mb-3" />
      </div>
    );
  }
}

export default SalesOrderInternalModal;
