import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  InputAdornment,
  Button as MuiButton,
  Autocomplete,
} from "@mui/material";

import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonGroup from "@mui/material/ButtonGroup";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import RadioGroup from "../../core/controls/RadioGroup";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import { CustomerSchema } from "../../schemas";
import { createEmployee } from "../../redux/actions/employeeActions";
import {
  createCustomer,
  deleteCustomer,
  listCustomerDetails,
  updateCustomer,
} from "../../redux/actions/customerActions";
import ConfirmDialog from "../../core/ConfirmDialog";
import { API_URL } from "../../redux/constants/apiConstants";
import {
  listChannel,
  listDesignation,
  listEmployeeByChannel,
} from "../../redux/actions/channelActions";

import "./Customer.css";
import { Circles } from "react-loader-spinner";

const genderItems = [
  { id: "male", title: "Male" },
  { id: "female", title: "Female" },
  { id: "other", title: "Other" },
];

const getDepartmentCollection = [
  { id: "1", title: "Development" },
  { id: "2", title: "Marketing" },
  { id: "3", title: "Accounting" },
  { id: "4", title: "HR" },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

const initialValues = {
  customerName: "",
  customerAddress: "",
  locationId: null,
  contactPersonName: "",
  contactNumber: "",
  designation: {
    id: "",
    label: "",
  },
  customerLat: null,
  customerLong: null,
  channel: {
    id: "",
    label: "",
  },
  employee: {
    id: "",
    label: "",
  },
};

const EditCustomer = ({ match, history }) => {
  const customerId = match.params.id;
  const dispatch = useDispatch();

  // const [designations, setDesignations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const classes = useStyles();

  const customerDetails = useSelector((state) => state.customerDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    customer,
  } = customerDetails;
  console.log("customer");
  console.log(customer);

  const channelList = useSelector((state) => state.channelList);
  const {
    loading: loadingChannels,
    error: errorChannels,
    channels,
  } = channelList;

  const designationList = useSelector((state) => state.designationList);
  const {
    loading: loadingDesignations,
    error: errorDesignations,
    designations,
  } = designationList;

  const employeesByChannelList = useSelector(
    (state) => state.employeesByChannelList
  );
  const {
    loading: loadingEmployeesByChannel,
    error: errorEmployeesByChannel,
    employeesByChannel,
  } = employeesByChannelList;
  // const { values, setValues, handleChange } = useForm(initialValues);

  const customerUpdate = useSelector((state) => state.customerUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = customerUpdate;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const formik = useFormik({
    initialValues,
    validationSchema: CustomerSchema,
    onSubmit: (values, action) => {
      // console.log(values);
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch("https://geolocation-db.com/json/", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          // console.log("result");
          // console.log(result);
          // console.log("final values");
          // console.log(values);
          dispatch(
            updateCustomer({
              customerId: values.customerId,
              customerName:
                values.customerName === "" ? null : values.customerName,

              customerAddress:
                values.customerAddress === "" ? null : values.customerAddress,
              locationId: null,
              contactPersonName:
                values.contactPersonName !== ""
                  ? values.contactPersonName
                  : null,
              contactNumber:
                values.contactNumber === "" ? null : values.contactNumber,
              designationId:
                values.designation.id === "" ? null : values.designation.id,

              employeeId: values.employee.id === "" ? 0 : values.employee.id,
              channelId: values.channel.id === "" ? null : values.channel.id,

              customerLat: null,
              customerLong: null,
            })
          ).then((result) => {
            if (result?.title === "One or more validation errors occurred.") {
              setOpenPopup({
                ...openPopup,
                title: "ERROR!!!",
                subTitle: "Something went wrong!",
                isOpen: true,
              });
              // action.setFieldError("error", result.errMsg);
              action.setSubmitting(false);
            } else if (result?.errMsg) {
              // console.log(result);
              if (result.errMsg == "Customer Already Exist") {
                action.setFieldError("errorClientName", result.errMsg);
              } else if (result.errMsg == "Contact Number Already Exist") {
                action.setFieldError("errorClientPhone", result.errMsg);
              }

              action.setSubmitting(false);
            } else {
              history.push("/customers");
            }
          });
        })
        .catch((error) => console.log("error", error));
    },
  });
  // console.log(customer);

  const changeValues = () => {
    console.log("customer");
    console.log(customer);
    formik.setValues({
      customerId: customer.customerId || "",
      customerName: customer.customerName || "",
      contactNumber: customer.contactNumber || "",
      contactPersonName: customer.contactPersonName || "",
      customerAddress: customer.customerAddress || "",
      customerLat: customer.customerLat || "",
      customerLong: customer.customerLong || "",
      locationId: customer.locationId || "",
    });
    formik.setFieldValue("function", {
      id: customer.functionId || "",
      label: customer.functionName || "",
    });

    formik.setFieldValue("designation", {
      id: customer.designationId || "",
      label: customer.designation || "",
    });

    formik.setFieldValue("channel", {
      id: customer.channelId || "",
      label: customer.channelName || "",
    });
    formik.setFieldValue("employee", {
      id: customer.employeeId === 0 ? "" : customer.employeeId,

      label:
        customer.employeeId === 0
          ? ""
          : customer.empFirstName !== null &&
            customer.empLastName !== null &&
            customer.employeeCode !== null
          ? customer.empFirstName +
              " " +
              customer.empLastName +
              "-" +
              customer.employeeCode || ""
          : "",
    });
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(listCustomerDetails(customerId));
      loadLocation();
      dispatch(listChannel());
      dispatch(listDesignation());
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: `/customer/${customerId}/edit` } },
      };
      history.push(location);
    }
  }, [customerId, dispatch, history, userInfo]);

  useEffect(() => {
    changeValues();
  }, [customer]);

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    dispatch(deleteCustomer(id));
    history.push("/customers");
  };

  const loadLocation = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_URL}/Location/GetLocations`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const final = result.dataObj.map((single) => {
          return {
            id: single.locationId,
            title: single.locationName,
          };
        });
        setLocations(final);
      })
      .catch((error) => console.log("error", error));
  };

  const onTagsChange = (event, values) => {
    // console.log(values);
    dispatch(listEmployeeByChannel(values.id));
  };

  const channelChange = useCallback((e, v) => {
    formik.setFieldValue("channel", {
      id: v?.id || "",
      label: v?.label || "",
    });
    onTagsChange(e, v);
  });
  const designationChange = useCallback((e, v) => {
    formik.setFieldValue("designation", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const employeeChange = useCallback((e, v) => {
    formik.setFieldValue("employee", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });
  // console.log(channels);
  // useEffect(() => {

  // }, []);

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Customers"
        subtitle="Edit Customer's Data"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Input
                label={
                  <Fragment>
                    Customer Name
                    <span style={{ color: "#C62828" }}> *</span>
                  </Fragment>
                }
                name="customerName"
                error={formik.errors.customerName}
                extraError={formik.errors.errorClientName}
                touched={formik.touched.customerName}
                value={formik.values.customerName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                label={
                  <Fragment>
                    Customer Address
                    <span style={{ color: "#C62828" }}> *</span>
                  </Fragment>
                }
                name="customerAddress"
                error={formik.errors.customerAddress}
                touched={formik.touched.customerAddress}
                value={formik.values.customerAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              {/* <Select
                variant="outlined"
                size="small"
                id="locationId"
                label="Customer Location"
                name="locationId"
                error={formik.errors.locationId}
                touched={formik.touched.locationId}
                value={formik.values.locationId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={locations}
              /> */}
              <Input
                label="Contact Person Name"
                name="contactPersonName"
                error={formik.errors.contactPersonName}
                touched={formik.touched.contactPersonName}
                value={formik.values.contactPersonName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                label="Contact #"
                name="contactNumber"
                error={formik.errors.contactNumber}
                extraError={formik.errors.errorClientPhone}
                touched={formik.touched.contactNumber}
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+88</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingDesignations ? true : false}
                id="combo-box-demo-channel"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={designationChange}
                value={formik.values.designation}
                options={designations !== undefined ? designations : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Designation"
                    name="designation"
                    error={
                      formik.errors.designation !== undefined
                        ? formik.errors.designation.id
                        : ""
                    }
                    touched={
                      formik.touched.designation !== undefined
                        ? formik.touched.designation
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingChannels ? true : false}
                id="channel"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={useCallback((e, v) => {
                  formik.setFieldValue("channel", {
                    id: v?.id || "",
                    label: v?.label || "",
                  });
                })}
                value={formik.values.channel}
                options={channels !== undefined ? channels : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label={
                      <Fragment>
                        Channel
                        <span style={{ color: "#C62828" }}> *</span>
                      </Fragment>
                    }
                    name="channel"
                    error={
                      formik.errors.channel !== undefined
                        ? formik.errors.channel.id
                        : ""
                    }
                    touched={
                      formik.touched.channel !== undefined
                        ? formik.touched.channel
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingEmployeesByChannel ? true : false}
                id="combo-box-demo-channelEmployee"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={employeeChange}
                value={formik.values.employee}
                options={
                  employeesByChannel !== undefined ? employeesByChannel : []
                }
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label={
                      <Fragment>
                        Employee By Channel
                        <span style={{ color: "#C62828" }}> *</span>
                      </Fragment>
                    }
                    name="employee"
                    error={
                      formik.errors.employee !== undefined
                        ? formik.errors.employee.id
                        : ""
                    }
                    touched={
                      formik.touched.employee !== undefined
                        ? formik.touched.employee
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ButtonGroup className={classes.submitButtonGroup} type="submit">
                <MuiButton
                  endIcon={
                    loadingUpdate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <PublishIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Update
                </MuiButton>
                <MuiButton
                  size="small"
                  startIcon={<ReplayIcon />}
                  onClick={() => {
                    formik.resetForm();
                  }}
                />
              </ButtonGroup>
              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/customers",
                    state: {},
                  };
                  history.push(location);
                }}
              />
              <MuiButton
                onClick={() => {
                  // onDelete(params.row.employeeId);
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure that you want to delete this data?",
                    subTitle: "Once it is deleted, you can't restore!",
                    onConfirm: () => {
                      onDelete(match.params.id);
                    },
                  });
                }}
                color="error"
                startIcon={
                  loadingDetails ? (
                    <CircularProgress size="1rem" color="error" />
                  ) : (
                    <DeleteIcon />
                  )
                }
                size="large"
              >
                Delete
              </MuiButton>
            </Grid>
          </Grid>
        </Form>
        <div
          className="paperdesign2"
          style={{
            display: loadingChannels && loadingDesignations ? "" : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              // wrapperClass=""
              // style={{
              //   width: "100%",
              //   height: "100%",
              //   display: "flex !important",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

export default withRouter(EditCustomer);
