import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { BiPaperPlane, BiCloudDownload } from "react-icons/bi";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import MailIcon from "@mui/icons-material/Mail";
import { Accordion, Form } from "react-bootstrap";
import "./View.css";
import styled from "@emotion/styled";

const arr = (x) => Array.from(x);
const num = (x) => Number(x) || 0;
const str = (x) => String(x);
const isEmpty = (xs) => xs.length === 0;
const take = (n) => (xs) => xs.slice(0, n);
const drop = (n) => (xs) => xs.slice(n);
const reverse = (xs) => xs.slice(0).reverse();
const comp = (f) => (g) => (x) => f(g(x));
const not = (x) => !x;
const chunk = (n) => (xs) =>
  isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

function GenerateInvoice(soSystemNo) {
  html2canvas(document.querySelector("#invoiceCapture")).then((canvas) => {
    const imgData = canvas.toDataURL("image/png", 1.0);
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: [612, 792],
    });
    pdf.internal.scaleFactor = 1;
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`invoice-${soSystemNo}.pdf`);
  });
}

function GenerateInvoice2() {
  // var pdf = new jsPDF('p','pt','a4');

  var pdf = new jsPDF("p", "pt", "a4");

  var imgData =
    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAXwBfAAD/2wBDAAoHBwkHBgoJCAkLCwoMDxkQDw4ODx4WFxIZJCAmJSMgIyIoLTkwKCo2KyIjMkQyNjs9QEBAJjBGS0U+Sjk/QD3/2wBDAQsLCw8NDx0QEB09KSMpPT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT3/wgARCAAaABQDAREAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAABQYAAwQB/8QAGAEBAQEBAQAAAAAAAAAAAAAAAwEAAgT/2gAMAwEAAhADEAAAAXKbOK1c92KOHzuQcxaHNjdidpy5yl//xAAfEAACAQMFAQAAAAAAAAAAAAABAgADEhMEEBEhIjH/2gAIAQEAAQUC+QuVq6duEqnoephWKDia/FLjLjt//8QAHREAAgIBBQAAAAAAAAAAAAAAAAIBEQMSEyAiMf/aAAgBAwEBPwEhIZLj2DOttcCkNp7G8xZfH//EAB4RAAIDAAEFAAAAAAAAAAAAAAABAgMREiAhIjFR/9oACAECAQE/AR2ONmS9MolkcZZ8aHDl4b2FTEaEun//xAAhEAABAwMEAwAAAAAAAAAAAAABAAIRAxAxEjJBQiFhYv/aAAgBAQAGPwJQ7acIg8FQWFzfS0B0t+shcpkNqHx1KqahU29rZKybf//EAB0QAQADAQACAwAAAAAAAAAAAAEAESExQVFhgZH/2gAIAQEAAT8hUFrUE1U6+ZZvXITcrvpNdp4xEO+l1b7Gv7BQdYMALdXDkpwD7ipT+kOT/9oADAMBAAIAAwAAABBnmCSOz//EABsRAQACAwEBAAAAAAAAAAAAAAEAESExYSBx/9oACAEDAQE/EAXUQdz5KIsIMuNjTLWFPNMVwaOQoRsVXn//xAAcEQEAAgIDAQAAAAAAAAAAAAABABEhMSBhcVH/2gAIAQIBAT8QUMsIdQ9/JZNpSUTIImK3bZ5AbtfZa3cpbvj/AP/EABwQAQACAwEBAQAAAAAAAAAAAAEAESExQXFRwf/aAAgBAQABPxCsIatahd4Y+dDAb93fjD4HtO4qLlXU0ej2pdETsO11xEdV8cP2hExkSA2d3NHkA0Q0CIxSEyKmjyf/2Q==";
  pdf.addImage(imgData, "JPEG", 20, 20, 20, 26);

  pdf.text(50, 40, "Header");

  pdf.addHTML(document.body, 40, 100, function () {
    pdf.save("web.pdf");
  });
}

class QuotationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productCategoryName: false,
      functionName: false,
      groupName: false,
      hsCode: false,
      brandName: false,
      countryOfOrigin: false,
      productLevel: false,
      productShelfLife: false,
      productPackSize: false,
      offerValidity: false,
      paymentTerm: false,
      deliveryLeadTime: false,
      vatAitCheck: false,
      reportRef: React.createRef(null),
    };
  }

  dateConverter = (date) => {
    var d = new Date(date);
    d = d.toString();
    console.log("d");
    console.log(d);
    d = d.split(" ");
    //   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //   day = ("0" + date.getDate()).slice(-2);
    d = [d[2], d[1], d[3]].join("-");
    return d;
  };

  getOldPrice = (price, vat, ait, discount) => {
    var lineTotalVat = price * (vat / 100);
    var lineTotalAit = price * (ait / 100);
    console.log("price");
    console.log(price);
    console.log("lineTotal");
    console.log(lineTotalVat + lineTotalAit + discount);
    var final = price - (lineTotalVat + lineTotalAit + discount);
    return final;
  };

  getVatAmount = (price, vat) => {
    var lineTotalVat = price * (vat / 100);

    return lineTotalVat.toFixed(2);
  };

  getAitAmount = (price, ait) => {
    var lineTotalAit = price * (ait / 100);
    return lineTotalAit.toFixed(2);
  };

  getTotalPrice = (arr, vat, ait, discount, adjustment) => {
    var sum = 0;
    arr.map((single) => {
      sum = sum + single.sodFinalCalculatedUnitPrice;
    });
    sum =
      parseFloat(sum) +
      parseFloat(vat) +
      parseFloat(ait) -
      (parseFloat(discount) + parseFloat(adjustment));
    console.log("sum");
    console.log(sum);
    return sum.toFixed(2);
  };

  getTotalQuantity = (arr) => {
    var sum = 0;
    arr.map((single) => {
      sum = sum + single.sodQty;
    });

    return parseFloat(sum).toFixed(2);
  };

  numberToEnglish = (n) => {
    var string = n.toString(),
      units,
      tens,
      scales,
      start,
      end,
      chunks,
      chunksLen,
      chunk,
      ints,
      i,
      word,
      words,
      and = "and";

    /* Remove spaces and commas */
    string = string.replace(/[, ]/g, "");

    /* Is number zero? */
    if (parseInt(string) === 0) {
      return "zero";
    }

    /* Array of units as words */
    units = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];

    /* Array of tens as words */
    tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    /* Array of scales as words */
    scales = [
      "",
      "thousand",
      "million",
      "billion",
      "trillion",
      "quadrillion",
      "quintillion",
      "sextillion",
      "septillion",
      "octillion",
      "nonillion",
      "decillion",
      "undecillion",
      "duodecillion",
      "tredecillion",
      "quatttuor-decillion",
      "quindecillion",
      "sexdecillion",
      "septen-decillion",
      "octodecillion",
      "novemdecillion",
      "vigintillion",
      "centillion",
    ];

    /* Split user argument into 3 digit chunks from right to left */
    start = string.length;
    chunks = [];
    while (start > 0) {
      end = start;
      chunks.push(string.slice((start = Math.max(0, start - 3)), end));
    }

    /* Check if function has enough scale words to be able to stringify the user argument */
    chunksLen = chunks.length;
    if (chunksLen > scales.length) {
      return "";
    }

    /* Stringify each integer in each chunk */
    words = [];
    for (i = 0; i < chunksLen; i++) {
      chunk = parseInt(chunks[i]);

      if (chunk) {
        /* Split chunk into array of individual integers */
        ints = chunks[i].split("").reverse().map(parseFloat);

        /* If tens integer is 1, i.e. 10, then add 10 to units integer */
        if (ints[1] === 1) {
          ints[0] += 10;
        }

        /* Add scale word if chunk is not zero and array item exists */
        if ((word = scales[i])) {
          words.push(word);
        }

        /* Add unit word if array item exists */
        if ((word = units[ints[0]])) {
          words.push(word);
        }

        /* Add tens word if array item exists */
        if ((word = tens[ints[1]])) {
          words.push(word);
        }

        /* Add 'and' string after units or tens integer if: */
        if (ints[0] || ints[1]) {
          /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
          if (ints[2] || i + 1 > chunksLen) {
            words.push(and);
          }
        }

        /* Add hundreds word if array item exists */
        if ((word = units[ints[2]])) {
          words.push(word + " hundred");
        }
      }
    }

    return words.reverse().join(" ");
  };

  NumInWords = (number) => {
    const first = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const mad = ["", "thousand", "million", "billion", "trillion"];
    let word = "";

    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number % (100 * Math.pow(1000, i));
      if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
        if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
          word =
            first[Math.floor(tempNumber / Math.pow(1000, i))] +
            mad[i] +
            " " +
            word;
        } else {
          word =
            tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
            "-" +
            first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
            mad[i] +
            " " +
            word;
        }
      }

      tempNumber = number % Math.pow(1000, i + 1);
      if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
        word =
          first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] +
          "hunderd " +
          word;
    }
    return word;
  };

  // ==========================================================================
  // Function Declaration :: Convert Numbers to Words :: Indian Format
  // ==========================================================================

  convertNumberToWords = (num) => {
    console.log("num");
    console.log(num);

    var ones = [
      "",
      "One ",
      "Two ",
      "Three ",
      "Four ",
      "Five ",
      "Six ",
      "Seven ",
      "Eight ",
      "Nine ",
      "Ten ",
      "Eleven ",
      "Twelve ",
      "Thirteen ",
      "Fourteen ",
      "Fifteen ",
      "Sixteen ",
      "Seventeen ",
      "Eighteen ",
      "Nineteen ",
    ];
    var tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    if ((num = num.toString()).length > 9)
      return "Overflow: Maximum 9 digits supported";
    const n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    console.log("n");
    console.log(n);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (ones[Number(n[1])] || tens[n[1][0]] + " " + ones[n[1][1]]) + "Crore "
        : "";
    str +=
      n[2] != 0
        ? (ones[Number(n[2])] || tens[n[2][0]] + " " + ones[n[2][1]]) + "Lakh "
        : "";
    str +=
      n[3] != 0
        ? (ones[Number(n[3])] || tens[n[3][0]] + " " + ones[n[3][1]]) +
          "Thousand "
        : "";
    str +=
      n[4] != 0
        ? (ones[Number(n[4])] || tens[n[4][0]] + " " + ones[n[4][1]]) +
          "Hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (ones[Number(n[5])] || tens[n[5][0]] + " " + ones[n[5][1]])
        : "";
    return str;
  };

  getNumbers = (inputNumber, currency) => {
    console.log("currency");
    console.log(currency);
    var num = inputNumber;
    var str = num.toString();
    var numarray = str.split(".");
    var a = new Array();
    a = numarray;
    console.log("a[0]");
    console.log(a[0]);
    console.log("a[1]");
    console.log(a[1]);
    inputNumber = a[0];
    console.log("NumToWord(inputNumber)");
    console.log(this.NumToWord(inputNumber));
    var s = this.NumToWord(inputNumber);

    if (a[1] === undefined) {
      var c = currency === "BDT" ? " Taka only." : "Dollar only";
      s = s + c;
    } else if (a[1] === "00") {
      var c = currency === "BDT" ? " Taka only." : "Dollar only";

      s = s + c;
    } else if (a[1][0] == 0) {
      var c =
        currency === "BDT"
          ? " Taka and Zero"
          : "Dollar and Zero" + this.NumToWord(a[1][1]) + currency === "BDT"
          ? " poisha only."
          : " penny only.";
      s = s + c;
    } else {
      var c =
        currency === "BDT"
          ? " Taka and "
          : " Dollar and " + this.NumToWord(a[1]) + currency === "BDT"
          ? " poisha only."
          : " penny only.";
      s = s + c;
    }
    console.log("s");
    console.log(s);
    console.log("NumToWord(a[1])");
    console.log(a[1][0]);
    return s;
  };

  NumToWord = (inputNumber) => {
    var str = new String(inputNumber);
    var splt = str.split("");
    var rev = splt.reverse();
    var once = [
      "Zero",
      " One",
      " Two",
      " Three",
      " Four",
      " Five",
      " Six",
      " Seven",
      " Eight",
      " Nine",
    ];
    var twos = [
      "Ten",
      " Eleven",
      " Twelve",
      " Thirteen",
      " Fourteen",
      " Fifteen",
      " Sixteen",
      " Seventeen",
      " Eighteen",
      " Nineteen",
    ];
    var tens = [
      "",
      "Ten",
      " Twenty",
      " Thirty",
      " Forty",
      " Fifty",
      " Sixty",
      " Seventy",
      " Eighty",
      " Ninety",
    ];

    var numLength = rev.length;
    var word = new Array();
    var j = 0;

    for (var i = 0; i < numLength; i++) {
      switch (i) {
        case 0:
          if (rev[i] == 0 || rev[i + 1] == 1) {
            word[j] = "";
          } else {
            word[j] = "" + once[rev[i]];
          }
          word[j] = word[j];
          break;

        case 1:
          aboveTens();
          break;

        case 2:
          if (rev[i] == 0) {
            word[j] = "";
          } else if (rev[i - 1] == 0 || rev[i - 2] == 0) {
            word[j] = once[rev[i]] + " Hundred ";
          } else {
            word[j] = once[rev[i]] + " Hundred and";
          }
          break;

        case 3:
          if (rev[i] == 0 || rev[i + 1] == 1) {
            word[j] = "";
          } else {
            word[j] = once[rev[i]];
          }
          if (rev[i + 1] != 0 || rev[i] > 0) {
            word[j] = word[j] + " Thousand";
          }
          break;

        case 4:
          aboveTens();
          break;

        case 5:
          if (rev[i] == 0 || rev[i + 1] == 1) {
            word[j] = "";
          } else {
            word[j] = once[rev[i]];
          }
          if (rev[i + 1] !== "0" || rev[i] > "0") {
            word[j] = word[j] + " Lakh";
          }

          break;

        case 6:
          aboveTens();
          break;

        case 7:
          if (rev[i] == 0 || rev[i + 1] == 1) {
            word[j] = "";
          } else {
            word[j] = once[rev[i]];
          }
          if (rev[i + 1] !== "0" || rev[i] > "0") {
            word[j] = word[j] + " Crore";
          }
          break;

        case 8:
          aboveTens();
          break;

        //            This is optional.

        //            case 9:
        //                if ((rev[i] == 0) || (rev[i + 1] == 1)) {
        //                    word[j] = '';
        //                }
        //                else {
        //                    word[j] = once[rev[i]];
        //                }
        //                if (rev[i + 1] !== '0' || rev[i] > '0') {
        //                    word[j] = word[j] + " Arab";
        //                }
        //                break;

        //            case 10:
        //                aboveTens();
        //                break;

        default:
          break;
      }
      j++;
    }

    function aboveTens() {
      if (rev[i] == 0) {
        word[j] = "";
      } else if (rev[i] == 1) {
        word[j] = twos[rev[i - 1]];
      } else {
        word[j] = tens[rev[i]];
      }
    }

    word.reverse();
    var finalOutput = "";
    for (i = 0; i < numLength; i++) {
      finalOutput = finalOutput + word[i];
    }
    return finalOutput;
  };

  getPriceVatContent(pqdAppliedVatPercantage, pqdAppliedAitPercantage) {
    if (pqdAppliedVatPercantage === 0 && pqdAppliedAitPercantage === 0)
      return "Price Excluding VAT & AIT";
    if (pqdAppliedVatPercantage !== 0 && pqdAppliedAitPercantage === 0)
      return `Price Including ${parseFloat(pqdAppliedVatPercantage).toFixed(2)}% Vat & Excluding AIT`;
    if (pqdAppliedVatPercantage === 0 && pqdAppliedAitPercantage !== 0)
      return `Price Excluding Vat & Including ${parseFloat(pqdAppliedAitPercantage).toFixed(2)}% AIT`;
    if (pqdAppliedVatPercantage !== 0 && pqdAppliedAitPercantage !== 0)
      return `Price Including ${parseFloat(pqdAppliedVatPercantage).toFixed(2)}% VAT & ${parseFloat(pqdAppliedAitPercantage).toFixed(2)}% AIT`;

    return null;
  }

  async GenerateInvoiceWithMultiplePage(soSystemNo) {
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
    });

    const pageHeight = pdf.internal.pageSize.getHeight();

    let currentPageHeight = 0;
    let currentPage = this.state.reportRef.current;

    const conveyanceHeader = document.querySelector(".invoice-header");
    let canvas = await html2canvas(conveyanceHeader);

    let canvasHeight =
      (canvas.height * pdf.internal.pageSize.getWidth()) / canvas.width;

    if (currentPageHeight + canvasHeight > pageHeight) {
      pdf.addPage();
      currentPageHeight = 0;
      currentPage = this.state.reportRef.current;
    }

    let imageData = canvas.toDataURL("image/png");

    pdf.addImage(
      imageData,
      "PNG",
      0.05,
      currentPageHeight,
      pdf.internal.pageSize.getWidth(),
      canvasHeight
    );
    currentPageHeight += canvasHeight;

    const conveyanceSubHeader = document.querySelector(
      ".invoice-price-quotation"
    );

    canvas = await html2canvas(conveyanceSubHeader);
    canvasHeight =
      (canvas.height * pdf.internal.pageSize.getWidth()) / canvas.width;

    if (currentPageHeight + canvasHeight > pageHeight) {
      pdf.addPage();
      currentPageHeight = 0;
      currentPage = this.state.reportRef.current;
    }

    imageData = canvas.toDataURL("image/png");

    pdf.addImage(
      imageData,
      "PNG",
      5,
      currentPageHeight,
      pdf.internal.pageSize.getWidth(),
      canvasHeight
    );
    currentPageHeight += canvasHeight;

    const conveyanceTableHeader = document.querySelector(
      ".invoice-table-header"
    );

    canvas = await html2canvas(conveyanceTableHeader);
    canvasHeight =
      (canvas.height * pdf.internal.pageSize.getWidth()) / canvas.width;

    if (currentPageHeight + canvasHeight > pageHeight) {
      pdf.addPage();
      currentPageHeight = 0;
      currentPage = this.state.reportRef.current;
    }

    imageData = canvas.toDataURL("image/png");

    pdf.addImage(
      imageData,
      "PNG",
      5,
      currentPageHeight,
      pdf.internal.pageSize.getWidth() - 10,
      canvasHeight
    );
    currentPageHeight += canvasHeight;

    const contentElements = document.querySelectorAll(".invoice-table-item");
    // console.log("content elements: ", contentElements);

    for (const element of contentElements) {
      const canvas = await html2canvas(element);

      const canvasHeight =
        (canvas.height * pdf.internal.pageSize.getWidth()) / canvas.width;

      if (currentPageHeight + canvasHeight > pageHeight) {
        pdf.addPage();
        let headerCanvas = await html2canvas(conveyanceTableHeader);
        let headerCanvasHeight =
          (headerCanvas.height * pdf.internal.pageSize.getWidth()) /
          headerCanvas.width;

        currentPageHeight = 0;
        currentPage = this.state.reportRef.current;

        let headerImageData = headerCanvas.toDataURL("image/png");

        pdf.addImage(
          headerImageData,
          "PNG",
          5,
          currentPageHeight,
          pdf.internal.pageSize.getWidth() - 10,
          headerCanvasHeight
        );
        currentPageHeight += headerCanvasHeight;
      }

      const imageData = canvas.toDataURL("image/png");

      pdf.addImage(
        imageData,
        "PNG",
        5,
        currentPageHeight,
        pdf.internal.pageSize.getWidth() - 10,
        canvasHeight
      );
      currentPageHeight += canvasHeight;
    }

    const conveyanceFooter = document.querySelector(".invoice-notes");

    canvas = await html2canvas(conveyanceFooter);
    canvasHeight =
      (canvas.height * pdf.internal.pageSize.getWidth()) / canvas.width;

    if (currentPageHeight + canvasHeight > pageHeight) {
      pdf.addPage();
      currentPageHeight = 0;
      currentPage = this.state.reportRef.current;
    }

    imageData = canvas.toDataURL("image/png");

    pdf.addImage(
      imageData,
      "PNG",
      0.05,
      currentPageHeight + 10,
      pdf.internal.pageSize.getWidth(),
      canvasHeight
    );

    pdf.save(`price-quotation-${soSystemNo}.pdf`);
  }

  render() {
    console.log("this.props.info");
    console.log(this.props.info);
    return (
      <div>
        <StyledQuotationModal
          fullscreen={true}
          show={this.props.showModal}
          onHide={this.props.closeModal}
          size="xl"
          centered
          className="full-sc"
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Row responsive>
            <div className="pb-4 px-4">
              <Row>
                <Col md={6}>
                  <Button
                    variant="primary"
                    className="d-flex align-items-center justify-content-center w-100"
                    onClick={() => {
                      // GenerateInvoice(10);
                      this.GenerateInvoiceWithMultiplePage(
                        this.props?.info?.data?.priceQuotationDetailList[0]
                          ?.pqSystemNo
                      );
                    }}
                  >
                    <BiPaperPlane
                      style={{
                        width: "15px",
                        height: "15px",
                        marginTop: "-3px",
                      }}
                      className="me-2"
                    />
                    Download
                  </Button>
                </Col>
                <Col md={6}>
                  {/* <Button
                  variant="outline-primary"
                  className="d-flex align-items-center justify-content-center w-100 mt-3 mt-md-0"
                  onClick={GenerateInvoice2}
                >
                  <BiCloudDownload
                    style={{ width: "16px", height: "16px", marginTop: "-3px" }}
                    className="me-2"
                  />
                  Download Copy
                </Button> */}
                </Col>
              </Row>
            </div>
            <Col
              md={8}
              lg={2}
              style={{
                transition: "all 0.5s ease-out",
              }}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Customize</Accordion.Header>
                  <Accordion.Body>
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Categoryname"
                      className=""
                      checked={this.state.productCategoryName}
                      onChange={(e) => {
                        this.setState({
                          productCategoryName: !this.state.productCategoryName,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Functionname"
                      className=""
                      checked={this.state.functionName}
                      onChange={(e) => {
                        this.setState({
                          functionName: !this.state.functionName,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Groupname"
                      className=""
                      checked={this.state.groupName}
                      onChange={(e) => {
                        this.setState({
                          groupName: !this.state.groupName,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="HS Code"
                      className=""
                      checked={this.state.hsCode}
                      onChange={(e) => {
                        this.setState({
                          hsCode: !this.state.hsCode,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Brandname"
                      className=""
                      checked={this.state.brandName}
                      onChange={(e) => {
                        this.setState({
                          brandName: !this.state.brandName,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Origin"
                      className=""
                      checked={this.state.countryOfOrigin}
                      onChange={(e) => {
                        this.setState({
                          countryOfOrigin: !this.state.countryOfOrigin,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Product Level"
                      className=""
                      checked={this.state.productLevel}
                      onChange={(e) => {
                        this.setState({
                          productLevel: !this.state.productLevel,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Product Shelf Life"
                      className=""
                      checked={this.state.productShelfLife}
                      onChange={(e) => {
                        this.setState({
                          productShelfLife: !this.state.productShelfLife,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Product Pack Size"
                      className=""
                      checked={this.state.productPackSize}
                      onChange={(e) => {
                        this.setState({
                          productPackSize: !this.state.productPackSize,
                        });
                      }}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Notes</Accordion.Header>
                  <Accordion.Body>
                    {/* <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Categoryname"
                      className=""
                      checked={this.state.productCategoryName}
                      onChange={(e) => {
                        this.setState({
                          productCategoryName: !this.state.productCategoryName,
                        });
                      }}
                    /> */}
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Payment Term- Cash/Cheque/LC within seven days of goods delivery."
                      className=""
                      checked={this.state.paymentTerm}
                      onChange={(e) => {
                        this.setState({
                          paymentTerm: !this.state.paymentTerm,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Offer validity for 15days."
                      className=""
                      checked={this.state.offerValidity}
                      onChange={(e) => {
                        this.setState({
                          offerValidity: !this.state.offerValidity,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Delivery Lead time - 24hours."
                      className=""
                      checked={this.state.deliveryLeadTime}
                      onChange={(e) => {
                        this.setState({
                          deliveryLeadTime: !this.state.deliveryLeadTime,
                        });
                      }}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col
              md={8}
              lg={10}
              style={{
                transition: "all 0.5s ease-out",
              }}
            >
              <div
                ref={this.state.reportRef}
                id="invoiceCapture"
                className=" pt-1 "
                style={{ position: "relative" }}
              >
                <div className="d-flex flex-row justify-content-between align-items-center bg-light w-100 py-4 px-2 invoice-header">
                  <div className="text-end pr-5 pl-5">
                    <img
                      src={process.env.PUBLIC_URL + "/images/trade.png"}
                      alt=""
                      width="130"
                      height="35"
                      style={{ width: "80px", height: "50px" }}
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <h4 className="fw-bold my-2">TRADE ZENTRUM</h4>
                    <h6 className="fw-bold text-secondary mb-1">
                      Reg. Office: House #21, Road #01, Block-C, Banasree, Rampura
                    </h6>
                    <h6 className="fw-bold text-secondary mb-1 d-flex align-items-center justify-content-between">
                      <div className="fw-bold text-secondary mb-1 d-flex align-items-center">
                        ☎️&nbsp;+8801792 527261
                        {/* <div>01670205047</div> */}
                      </div>{" "}
                      &nbsp; &nbsp; &nbsp;
                      <div className="fw-bold text-secondary mb-1 d-flex align-items-center">
                        📩&nbsp;tradezentrumbd@gmail.com
                        {/* <div>tradezentrumbd@gmail.com</div> */}
                      </div>
                    </h6>
                  </div>
                  <div className="text-end pr-5 pl-5">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/assutext-logo.jpeg"
                      }
                      alt=""
                      width="130"
                      height="35"
                      style={{ width: "120px", height: "50px" }}
                    />
                  </div>
                </div>
                <div
                  className="p-4"
                  style={{
                    // backgroundImage:
                    //   "url(" +
                    //   `${process.env.PUBLIC_URL}/images/invoice.png` +
                    //   ")",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div
                    className="invoice-price-quotation"
                    style={{ padding: "10px" }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <span
                        style={{
                          fontWeight: "bolder",
                          padding: "5px",
                        }}
                      >
                        PRICE QUOTATION
                      </span>
                    </div>
                    <Row style={{ marginBottom: "3rem", height: "100px" }}>
                      <Col md={7} lg={8}>
                        <div>
                          Quotation&nbsp;Number:&nbsp; :{" "}
                          {this.props.info.data.priceQuotationMasterData
                            ?.pqSystemNo || ""}
                        </div>
                        <div>
                          Date Of Issue:&nbsp;
                          {this.dateConverter(
                            this.props.info.data.priceQuotationMasterData
                              ?.pqOrderDate
                          ) || ""}
                        </div>
                      </Col>
                      {/* <Col md={4}>
                  <div className="fw-bold">Billed From:</div>
                  <div>{this.props.info.billFrom || ""}</div>
                  <div>{this.props.info.billFromAddress || ""}</div>
                  <div>{this.props.info.billFromEmail || ""}</div>
                </Col> */}
                      <div>
                        Customer&nbsp;Name:&nbsp; :{" "}
                        {this.props.info.data.priceQuotationMasterData
                          ?.customerName || ""}
                      </div>
                      <div>
                        Customer Address:&nbsp;
                        {this.props.info.data.priceQuotationMasterData
                          .customerAddress !== null
                          ? this.props.info.data.priceQuotationMasterData
                              .customerAddress
                          : "Not Available"}
                      </div>
                    </Row>
                  </div>
                  <div
                    style={{
                      width: "auto",
                      overflowX: "scroll",
                      padding: "10px",
                    }}
                    className="row-scrollers"
                  >
                    <Table
                      className="mb-0"
                      style={{
                        border: "1px solid #e3e3e3",
                      }}
                    >
                      <thead>
                        <tr className="invoice-table-header">
                          <th>PRODUCT NAME</th>
                          {this.state.productCategoryName && <th>Category</th>}
                          {this.state.functionName && <th>Function</th>}

                          {this.state.groupName && <th>Group</th>}
                          {this.state.hsCode && <th>HS Code</th>}

                          {this.state.brandName && <th>Brand</th>}
                          {this.state.countryOfOrigin && <th>Origin</th>}

                          {this.state.productLevel && <th>Product Level</th>}
                          {this.state.productShelfLife && <th>Shelf Life</th>}
                          {this.state.productPackSize && <th>Pack Size</th>}
                          <th className="text-end">PRICE/KG</th>
                          {/* <th className="text-end">AMOUNT</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.info.data.priceQuotationDetailList.map(
                          (item, i) => {
                            console.log("item");
                            console.log(item);
                            return (
                              <tr
                                id={i}
                                key={i}
                                style={{ height: "20px" }}
                                className="invoice-table-item"
                              >
                                <td>{item.productName}</td>
                                {this.state.productCategoryName && (
                                  <td>{item.productCategoryName}</td>
                                )}
                                {this.state.functionName && (
                                  <td>{item.functionName}</td>
                                )}

                                {this.state.groupName && (
                                  <td>{item.groupName}</td>
                                )}
                                {this.state.hsCode && <td>{item.hsCode}</td>}

                                {this.state.brandName && (
                                  <td>{item.brandName}</td>
                                )}
                                {this.state.countryOfOrigin && (
                                  <td>{item.countryOfOrigin}</td>
                                )}

                                {this.state.productLevel && (
                                  <td>{item.productLevel}</td>
                                )}
                                {this.state.productShelfLife && (
                                  <td>{item.productShelfLife}</td>
                                )}
                                {this.state.productPackSize && (
                                  <td>{item.productPackSize || "0"}</td>
                                )}

                                {/* <td style={{ width: "70px" }}>{item.sodQty}</td> */}
                                <td
                                  className="text-end"
                                  style={{ width: "100px" }}
                                >
                                  {/* {this.props.info.stockOutBillData.sobCurrencyName}{" "} */}
                                  {/*item.pqdProductUnitPrice*/}
                                  {this.props.info.data.priceQuotationMasterData
                              .pqCurrencyName}{" "}
                                
                                  {item.pqdFinalPrice}
                                </td>
                                {/* <td
                                  className="text-end"
                                  style={{ width: "100px" }}
                                > */}
                                {/* {this.props.info.stockOutBillData.sobCurrencyName}{" "} */}
                                {/* {item.pqdFinalPrice} */}
                                {/* </td> */}
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="p-4 invoice-notes">
                  <h5>Notes:</h5>
                  {/* {(this.state.paymentTerm || this.state.offerValidity) && (
                    <h5>Notes:</h5>
                  )} */}
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      {this.getPriceVatContent(
                        this.props?.info?.data?.priceQuotationDetailList[0]
                          ?.pqdAppliedVatPercantage,
                        this.props?.info?.data?.priceQuotationDetailList[0]
                          ?.pqdAppliedAitPercantage
                      )}
                    </li>
                    {this.state.vatAitCheck && (
                      <li>
                        Payment Term- Cash/Cheque/LC within seven days of goods delivery.
                      </li>
                    )}
                    {this.state.paymentTerm && (
                      <li>
                        Payment Term- Cash/Cheque/LC within seven days of goods delivery.
                      </li>
                    )}
                    {this.state.offerValidity && (
                      <li>Offer validity for 15days.</li>
                    )}
                    {this.state.deliveryLeadTime && (
                      <li>Delivery lead time - 24 hours.</li>
                    )}
                    {/* <li></li> */}
                  </ul>
                </div>

                <div className="p-4 " style={{ display: "none" }}>
                  <Row className="mb-4 ">
                    <Col md={3} lg={3}>
                      <div
                        className="fw-bold text-center w-55 h-55 p-1"
                        style={{
                          height: "120px",
                          border: "1px solid #e3e3e3 ",
                        }}
                      >
                        Authorized By:
                      </div>
                      {/* <div>{this.props.info.billTo || "Hello Uttara"}</div>
                  <div>{this.props.info.billToAddress || ""}</div>
                  <div>{this.props.info.billToEmail || ""}</div> */}
                    </Col>
                    <Col md={3} lg={3}>
                      <div
                        className="fw-bold text-center w-55 h-55 p-1"
                        style={{
                          height: "120px",
                          border: "1px solid #e3e3e3 ",
                        }}
                      >
                        Prepared By:
                      </div>
                      {/* <div>{this.props.info.billTo || "Hello Uttara"}</div>
                  <div>{this.props.info.billToAddress || ""}</div>
                  <div>{this.props.info.billToEmail || ""}</div> */}
                    </Col>

                    <Col md={3} lg={3}>
                      <div
                        className="fw-bold text-center w-55 h-55 p-1"
                        style={{
                          height: "120px",
                          border: "1px solid #e3e3e3 ",
                        }}
                      >
                        Mode of Transport:
                      </div>
                      {/* <div>{this.props.info.billTo || "Hello Uttara"}</div>
                  <div>{this.props.info.billToAddress || ""}</div>
                  <div>{this.props.info.billToEmail || ""}</div> */}
                    </Col>

                    <Col md={3} lg={3}>
                      <div
                        className="fw-bold text-center w-55 h-55 p-1"
                        style={{
                          height: "120px",
                          border: "1px solid #e3e3e3 ",
                        }}
                      >
                        Received By:
                      </div>
                      {/* <div>{this.props.info.billTo || "Hello Uttara"}</div>
                  <div>{this.props.info.billToAddress || ""}</div>
                  <div>{this.props.info.billToEmail || ""}</div> */}
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </StyledQuotationModal>
        <hr className="mt-4 mb-3" />
      </div>
    );
  }
}

const StyledQuotationModal = styled(Modal)`
  @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

  .modal-dialog {
    font-family: "Roboto", sans-serif;
    .modal-content {
      overflow-y: auto;
    }
  }
`;

export default QuotationModal;
